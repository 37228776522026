<template>
  <v-row>
    <v-col cols="12" class="py-1 px-0">
      <v-simple-table
        hide-default-footer
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table version version-table"
        :class="{'progress-linear': loading}"
        >
      <template v-if="loading">
        <v-progress-linear
        class="mt-0"
        indeterminate
        />
        <tbody class="loading-text">
            <tr>
              <td>Loading data from API...please wait</td>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <tbody>
            <tr>
              <td>{{ $t("settings.version.systemVersion") }}:</td>
              <td>
                {{ data.localversion ? data.localversion : "" }}

                <v-btn
                  v-permission="['write version']"
                  color="primary"
                  class="ml-2"
                  small
                  @click="offlineUpdate"
                >
                  Upload Update
                </v-btn>
              </td>
            </tr>
            <tr>
              <td>{{ $t("settings.version.conectionStatus") }}:</td>
              <td>{{ data.status }}</td>
            </tr>
            <tr>
              <td>{{ $t("settings.version.lastVersion") }}:</td>
              <td>
                {{ data.updatedata.version.Last_version }}
                <v-btn
                  v-if="data.updateavailable === 'true'"
                  v-permission="['write version']"
                  color="primary"
                  class="ml-2"
                  @click="onlineUpdate"
                >
                  Online Update
                </v-btn>
              </td>
            </tr>
            <tr>
              <td>{{ $t("settings.version.Date") }}:</td>
              <td>{{ data.updatedata.version.Date }}</td>
            </tr>
            <tr>
              <td>{{ $t("settings.version.systemInfo") }}:</td>
              <td>
                <span
                  class="d-inline-block mr-3"
                  v-for="(item, key) in data.updatedata.version.System_info"
                  :key="key"
                >
                  {{ key }}: {{ item }}
                </span>
              </td>
            </tr>
            <tr>
              <td>{{ $t("settings.version.hardwareInfo") }}:</td>
              <td>
                <span
                  class="d-inline-block mr-3"
                  v-for="(item, key) in data.updatedata.version.Hardware_info"
                  :key="key"
                >
                  {{ key }}: {{ item }}
                </span>
              </td>
            </tr>
            <tr>
              <td>{{ $t("settings.version.changelog") }}:</td>
              <td>
                <a href="https://www.synapsa.tech/release-notes/" target="_blank">https://www.synapsa.tech/release-notes/</a>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import permission from "@/directive/permission/permission.js";
export default {
  directives: { permission },
  props: {
    loading: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    data: {
      type: Object,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
  },
  methods: {
    offlineUpdate() {
      this.idAddForm = 1;
      this.keywordAddForm = "systemVersion";
    },
    onlineUpdate() {
      this.idAddForm = 0;
      this.keywordAddForm = "systemVersion";
    },
  },
};
</script>

<style lang="scss">
.version-table {
  .v-chip.v-size--x-small {
    border-radius: 8px;
    font-size: 12px;
    height: 18px;
  }
}
.progress-linear {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.system-container {
.v-data-table__wrapper {
  border-radius: 0px;
}
}
.theme--dark{
  .loading-text {
    text-align: center;
    color: rgba(255, 255, 255, 0.5)
  }
}
.theme--light {
  .loading-text{
    text-align: center;
    color: rgba(0,0,0,.38);
  }
}
</style>
